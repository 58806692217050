import "../../core/src/components/sign-in/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/sign-in/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VUwXKbMBC9+yv22B7kARInrXLrnwixEDVixUhLYreTf+9IYAI2TO0TM9K+t/vePrHXzz2pMoO/O4BW+caQYNdJeMi648vuc7cfCvKNAgDtrPMS3pX/JoSqa0MoGI8sAmpHlfInkUq+x+LaEYtg/uAFIJ0fQ6qx8eAVTfPKEvL9YTGFSnOs9bSG3qZOM8Q+Xgyo3ocI65whRr81/DpRMTNAgurZvXwZUjpm10p4HCxJ8pU1DUnQOPSaiB4SUWVCZ9VJQm0xYX73gU0dvSJGYgmhUxpFifyBSCu9Lhb0eJsxAKXSb413PVUS2CsKnfJInK6cr9CLkeTi8u7VFcXgRh/Qi4AWNUsgRzib+nAVq2Ip6+mqIB9zN55ZrFmCOCwOzx7lT9sZfS+GmEZ75ot+3jRyC/FjfaONitOOJqQ0CMPYhq9MAExeLUUNQot1TcXCoJ+peewpIZ9TZrONTtjuCMFZU10Js+qEXhgKpsJfZ1D8zIVuP76zNdObv/G1T9Tlf6k7b9rbiceaj9GMQ5bNmulbV3yXhOru4OB6cOJXVMajZuPiL8TZvqXNFH3+Aw6dtQnEBQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addSelfhostedButton = 'c7unab6';
export var addSelfhostedButtonPrefix = 'c7unab7';
export var authMessage = 'c7unab1';
export var authModalContent = 'c7unab0';
export var captchaWrapper = 'c7unab2';
export var linkButton = 'c7unab4';
export var passwordButtonRow = 'c7unab3';
export var resendButtonWrapper = 'c7unab5';
export var skipDivider = 'c7unab8';
export var skipDividerLine = 'c7unab9';
export var skipDividerText = 'c7unaba';
export var skipLink = 'c7unabc';
export var skipLinkIcon = 'c7unabd';
export var skipSection = 'c7unabe';
export var skipText = 'c7unabb';