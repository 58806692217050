import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X246bMBB9z1eMWq20+2AE5LJbVqr6J5XBQ+LG2Mh2Ltsq/15hDAECCVv1KWHwzJw5czM/9viRa1qggZ+ReY1srJbwZwEQPrkfAKupNLnSRQJaWWrxOWS4fXlfAFwWAOs7B6O33tEovHN2uemcvSyCBk0IgVBb5fRyJS0x/DcmcKT6mRCa51wicfIdWTpt93BCvt3ZBDZhWMkyJZQeKKWCZnunUVC95ZJor1KeK+ERteUZFYQKvpUJFJwxge99aN+hTHKujSUqJ/ajxDkw15MwPZBUWauKBFY1ElHp7vzJ+K0WMm5KQT8SyAU6gYNJuMXCJJChtKhHwAr6OayrSawjqDreIme8pIxxuW0DWoY1+FIZbrmSCWgU1PJjn9coOHG7UwdLdlzaUUtxeONQ0BSFO9xykwqV7afyb/FsicFMSUb1B3FHXqazcJcoU8zkKR7Ajh8nwdu+ckZTo8TBYiUdgOz7ARCY2wTm4AhQa6UdmjGy3NuWo57iiWrJ5XZS1b8fU145pRNndpdAtO7H0DyPFUsVuGaoiaaMH0xSTaFKqo6oc6FOCew4YyjdSZrtt1odJCOjY6A2NIYuSWhusSFFWpQ2gS9f3juY4wHmeIi5m6xONvyscf+tKv2/JpudYZAAPVg1HsfXPM+rN78JlwzPCcRTzPSjSjFXGu+EFYeDcvLPj6gUB7xTq3X4PlNNqP6xDSFyjDTbgSjNHQmVrtdxw07ygtYerlsrCtYGuMy55BZdvdP+CFw/braUmjqEXrtMLxs/K1wKl8OZtAY62RWCy/1Y0W1Gx91q3uB87Y+/ZjX8OhjL82rE+WSbkmZIUrQnrHvk0fJ4mzkY5o0yN3n9Wm2cDRiPgvWn+6gtqZqrfv90gvnmgmk8LeOJhXrD2hXqKF0PiuEbBBoNutZheN1sYztjDon9S8tw/9JejFEYHnfdsRWGx9N/CnpOO/WABSdNy9KP1XnXmC7wpwcwb1dA37tXcd4Leibe8ls0TBiFwHIrZlyT3LG5l7qbqxKFwBzSq6vH5z8/VdJH5fA0Wg3VL2FcY1Y3YabEoZD/UicdLFl36S9nXgl9Fio8Zqe53Pue92Y2rz57PwpknILJNKIEKhk8F1ySNtB4VZ5fnK2pmmgTUE+ZOPQAATqG1h1p/+OhlTusW10VYtSR9NB7WUoNN150mQjiWqkTQfy52hvNWLtUXL7Lc7vUR5JZI7VU2+bTbap/73v8pOFhGtqQN1e6ByV7e03qGWXeVlvZUkmcR/O64mfAcubNDUBcFpe/18YHYkcPAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_1s71t2o6';
export var authCodeErrorMessage = '_1s71t2o8';
export var authCodeWrapper = '_1s71t2o7';
export var authContent = '_1s71t2o5';
export var authInputWrapper = '_1s71t2o1';
export var authPageContainer = '_1s71t2oa';
export var formHint = '_1s71t2o2';
export var hideInSmallScreen = '_1s71t2od';
export var illustration = '_1s71t2oe';
export var input = '_1s71t2oc';
export var loading = '_1s71t2o4';
export var modalHeaderWrapper = '_1s71t2o0';
export var resendButtonWrapper = '_1s71t2o9';
export var signInPageContainer = '_1s71t2ob';