import "../../core/src/desktop/dialogs/setting/workspace-setting/members/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/members/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVyW7bMBC9+yuIAAWSAw1ZzeIwt/5AD/0CWhxJk1AkQY68tOi/F6QWS0oTI2h6kri8Wd8brquseHk2Gfu1YszZgITWCOZBS8I9PK0Ys04WSCfBsvVtXDfSV2g4WSdYfuuOT6vfq3VvRghZEvhkrbCGwJBgV1cRdkBFtWCbLPsSlzVgVdN5ffYtd8HqlpJvDSUJlsXf5C/9Fa0P1gtmLHGptT2AmsSw6VKRSqGpBEsBMrazXoHnXipsg2CbvN+WxUvlbWuUYHvprzmXZYkG+D7nWp7A8/MN7jw20p9uzvYE27gjC1ajegOOJqCCb533DpTwCoPT8iRYqSFFEr9coYeiq0JhdduYePLcBsLyxMd6BicL4DugA4CZZJ6nzCs59uUDbkYjX5OREYdGx3wGuNRYGY4ETRCsAEPgJ9jbhC2tIR7wJyxKmvaPIaVfWB07uKwZwZE4NK6WAfuLfa+dxc7ZyL+OGss8u3gnMd31XPyrv0CS2sDBexvbMoLu50UYsn/ViaECi8LE+z9IerqUaoDCGjVwKnWuT2jk77ZbXy7qGPzDXABZNMkytrnvLA3Cu9u+QZGFUN9v+YOo7b4X/IfElGDf9+C1nCpqVOh2NlgehLF0LbQMxG3J6eTgJvnsybCzRLYR7H6G2qYrfT75XfZBTfQnofZoXvrZMyPfMEX6Td8VNs9mQTx2ooiWKm8Pgm3SUN2DL3Vc1qgUJG+JEucD0BpdwJB6UiMBT8KPc+/gpbvIitBMWfG4dmAiI97Tw1J/I1peVnZy977l6QD9nAKk4TQwOs9nhd99yjSaS/R/RD2nS/H30fM2R9PUGJh4Vv3EpPonk9PnfrE3qG6RArx6gXs1/wGa30lsbQgAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var confirmAssignModalContent = 'g0ckjnc';
export var confirmInputContainer = 'g0ckjnd';
export var errorStyle = 'g0ckjn5';
export var fakeWrapper = 'g0ckjn0';
export var goUpgrade = 'g0ckjn4';
export var goUpgradeWrapper = 'g0ckjn3';
export var importButton = 'g0ckjne';
export var localMembersPanel = 'g0ckjn2';
export var memberContainer = 'g0ckjn8';
export var memberEmail = 'g0ckjnb';
export var memberListItem = 'g0ckjn7';
export var memberName = 'g0ckjna';
export var membersFallback = 'g0ckjn6';
export var membersPanel = 'g0ckjn1';
export var roleOrStatus = 'g0ckjn9';